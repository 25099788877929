.header-audit-title{
    font: normal normal 600 30px/40px Montserrat;
    letter-spacing: 0.15px;
    color: #484848;
    margin:20px 0 25px 0;
}
.security {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
    margin: 50px 0 50px 0;
}

.security .grade{
    width: 90%;
    border: 1px solid #484848;
}
.security .pass{
    border: 1px solid #3BBE13;
}
.security .fail{
    border: 1px solid #F6491F;
}

.security .pass,.security .fail{
    width: 90%;
}
.security div span{
    display: block;
    text-align: center;
}

.security div span:nth-child(1) {
    font: normal normal bold 30px/48px Montserrat;
    color: #484848;
}
.security .pass span:nth-child(2) {
    color: #3BBE13;
}
.security .fail span:nth-child(2) {
    color: #F6491F;
}

.security div{
    padding: 30px;
    border-radius: 10px;
}

.sec-expert{
    grid-column-start: 1;
    grid-column-end: 4;
    border: 1px solid #F6491F;
    width: 92%;
    margin-top: 20px;
}

.security .sec-expert span:nth-child(1) {
    font: normal normal bold 15px/30px Montserrat;
}