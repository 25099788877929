/* Style the Fonts */
@import url('https://fonts.googleapis.com/css?family=Montserrat');
/* @font-face {
    font-family: 'normal normal normal 18px/22px Montserrat';
    src: url('https://fonts.googleapis.com/css?family=Montserrat') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
} */

html {
  overflow-y: scroll;
}

body{
  font: normal normal normal 15px/22px Montserrat !important;
  overflow-x: hidden;
}

.header h1 {
    letter-spacing: 0px;
    color: #232121;
    font: normal normal bold 36px/48px Montserrat;
}
.over-infos.sev {
    padding: 0px;
    border: none;
    margin: 30px 0px;
}
i.fa.fa-exclamation-triangle {
    background-color: #F6491F;
    font-size: 14px;
    align-items: center;
    margin-right: 15px;
    color: #fff;
    border-radius: 50%;
    display: grid;
    height: 22px;
    width: 22px;
    justify-content: center;
}
.status {
    font: normal normal bold 22px/30px Montserrat;
    letter-spacing: 0.11px;
    margin: 30px 0px !important;
    color: #413F3F;
    text-align: center;
}
.status img {
    margin-left: 5px;
}
/* Style the tab */

button.tablinks {
    width: 231px;
    height: 44px;
    font: normal normal 600 18px/24px Montserrat;
    letter-spacing: 0.09px;
    color: #FFFFFF;
    opacity: 1;
}
button.tablinks {
    background: #F0F3F6 0% 0% no-repeat padding-box;
    font: normal normal 600 20px/27px Montserrat;
    letter-spacing: 0.1px;
    color: #525252;
}
.tab {
  overflow: hidden;
  border-bottom: 1px solid #ccc;
}

.tab button {
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0px;
    transition: 0.3s;
    font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
    background: #3F42EF 0% 0% no-repeat padding-box;
    width: 231px;
    height: 44px;
    color: #fff;
    opacity: 1;
    padding: 0px;
}
.header {
    margin-bottom: 30px;
}
.tabcontent .tab button .fa {
    font-size: 28px;
    margin-right: 5px;
}
/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border-top: none;
}
.header p {
    font: normal normal normal 19px/26px Montserrat;
    letter-spacing: 0px;
    color: #707070;
}
.status span {
    font: normal normal bold 22px/30px Montserrat;
  letter-spacing: 0.11px;
}
.over-info p {
    border: 1px solid #C0C0C0;
    border-radius: 8px;
    opacity: 1;
    justify-content: center;
    font: normal normal 600 20px/27px Montserrat;
    letter-spacing: 0.1px;
    color: #413F3F;
    display: flex;
    height: 76px;
    align-items: center;
}
.over-infos.intab p {
    font: normal normal normal 15px/22px Montserrat;
    letter-spacing: 0.09px;
    color: #2A2A2A;
    text-align: left;
}
.over-infos.intab h5 {
    font: normal normal bold 16px/21px Montserrat;
    letter-spacing: 0.08px;
    color: #413F3F;
    text-align: left;
    position: relative;
}
.over-infos.lab {
    margin-top: 30px;
}
.over-infos h5 img {
    margin-right: 5px;
}
.over-infos h5 {
    margin-top: 15px;
    font: normal normal bold 17px/27px Montserrat;
    letter-spacing: 0.1px;
    color: #413F3F;
    display: flex;
    align-items: center;
}
.over-infos.intab h5::before {
    position: absolute;
    content: "";
    height: 10px;
    left: -20px;
    top: 5px;
    border-radius: 50%;
    width: 10px;
}
.over-infos.intab.active h5::before {
  background-color: #3BBE13;
}
.over-infos {
    border: 1px solid #C0C0C0;
    border-radius: 10px;
    opacity: 1;
    padding: 10px 30px;
}
.over-infosin {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    margin-top: 20px;
    padding: 10px 40px;
    margin-bottom: 20px;
}
.over-infos.intab.active{
  border: 1px solid #3BBE13;
  background: #D6FFC9 0% 0% no-repeat padding-box;
}
.result {
    justify-content: space-between;
    padding: 0px 40px;
}
.result li {
    border-bottom: 1px solid #ccc;
    padding: 20px 0px;
    align-items: center;
    display: flex;
    width: 46% !important;
}
.tabcontent .tab button img {
    margin-right: 10px;
}
.result li span.ml-auto {
    font: normal normal bold 24px/32px Montserrat;
    letter-spacing: 0.12px;
}
.result li i.fa.fa-circle {
    color: #3BBE13;
    margin-right: 15px;
    position: relative;
    top: 5px;
}
.result li span {
    font: normal normal 600 15px/27px Montserrat;
    letter-spacing: 0.1px;
}
.tabcontent .tab {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}
.tabcontent .tab button {
    margin: 0px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 60px;
    font: normal normal 600 20px/27px Montserrat;
    letter-spacing: 0.1px;
    color: #585858;
    border-radius: 12px;
}
.tabcontent .tab button.active {
    width: 150px;
    color: #fff;
    height: 60px;
    background: #3F42EF 0% 0% no-repeat padding-box;
    border-radius: 12px;
}
.over-infos.sev .over-infos {
    background: #FDFDFD 0% 0% no-repeat padding-box;
    border: 1px solid #BFBFBF;
    border-radius: 16px;
    align-items: center;
    margin-top: 20px;
}
.over-grade .over-grade-in.orange {
    background: var(--average) 0% 0% no-repeat padding-box;
    background: #F6A717 0% 0% no-repeat padding-box;
}
i.fa.fa-check {
    width: 23px;
    height: 23px;
    color: #fff;
    font-size: 20px;
    border-radius: 50%;
    background-color: #3BBE13;
}
.over-infos.sev.img-op .over-infos.grade {
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
    padding: 30px 0px;
}
span.txt-red {
    font: normal normal normal 20px/27px Montserrat;
    letter-spacing: 0.1px;
    color: #F6491F;
}
.over-infos.grade {
    display: grid;
    grid-template-columns: 1fr 2fr;
}
.over-grade .over-grade-in {
    width: 115px;
    height: 107px;
    background: var(--god) 0% 0% no-repeat padding-box;
    background: #3BBE13 0% 0% no-repeat padding-box;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.over-grade {
    display: flex;
    align-items: center;
}
.over-grade-in span {
    font: normal normal bold 43px/57px Montserrat;
    letter-spacing: 0.21px;
    color: #FFFFFF;
}
.over-grade .grade span {
   font: normal normal bold 20px/27px Montserrat;
  letter-spacing: 0.1px;
  color: #413F3F;
  margin-left: 30px;
}
.content-grade {
    text-align: left;
}
.over-grade-in {
    margin: 10px 0px;
    text-align: left;
    font: normal normal bold 20px/27px Montserrat;
    letter-spacing: 0.1px;
    color: #413F3F;
}
.grade p {
    font: normal normal normal 20px/27px Montserrat;
    letter-spacing: 0.1px;
    color: #413F3F;
}
.over-infos.experient {
    margin-top: 30px;
}
.over-grade-in.red {
    background: #F6491F 0% 0% no-repeat padding-box;
    border-radius: 20px;
}
.over-infosin.exp .over-infos.intab {
    border: none;
}
.over-infosin.exp {
    padding: 10px 0px;
}
.over-infosin.exp .fa , .btn-top .fa{
    font-size: 22px;
}
.over-infosin.exp i.fa.fa-smile-o {
    color: #3BBE13;
    margin-left: 10px;
}
.over-infosin.exp i.fa.fa-meh-o {
    color: #F6A717;
    margin-left: 10px;
}
.over-infosin.exp i.fa.fa-frown-o {
    color: #F6491F;
    margin-left: 10px;
}
.inner {
    background: #fafafa;
    border-radius: 12px;
}
.over-infosin.exp .over-infos.intab h5::before {
    content: unset;
}
.over-infosin.exp .over-infos.intab h5 {
    font: normal normal bold 16px/24px Montserrat;
    letter-spacing: 0.09px;
    text-align: center;
    color: #413F3F;
    display: flex;
    justify-content: center;
}
.btn-top {
    width: 60%;
    margin: auto;
    padding: 20px 0px;
    display: flex;
    justify-content: center;
}
p.text-left {
    font: normal normal normal 15px/27px Montserrat;
    letter-spacing: 0.1px;
    color: #413F3F;
}
.txt-green{
   font: normal normal 600 15px/27px Montserrat;
  letter-spacing: 0.1px;
  color: #3BBE13;
}
.btn-area-in {
    width: 30%;
    font: normal normal bold 21px/28px Montserrat;
    letter-spacing: 0.1px;
    color: #FFFFFF;
    margin: 0% 2%;
    padding: 20px 20px;
    text-align: center;
}
.btn-area-in.green {
    background: #3BBE13 0% 0% no-repeat padding-box;
}

.btn-area-in.orange {
    background: #F6A717 0% 0% no-repeat padding-box;
}

.btn-area-in.red {
    background: #F6491F 0% 0% no-repeat padding-box;
}

.accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
}

button.accordion {
    display: flex;
    border: 1px solid #B2C3DE;
    align-items: center;
    padding: 0px 10px 0px 0px;
    margin: 3px 0px;
    background-color: #fff;
    justify-content: space-between;
}

button.accordion h6 {
    margin: 0px;
    height: 47px;
    font: normal normal bold 14px/21px Montserrat;
    letter-spacing: 0.08px;
    color: #413F3F;
    display: flex;
    align-items: center;
}
button.accordion h6 span {
    text-align: left;
    font: normal normal 600 20px/27px Montserrat;
    letter-spacing: 0.1px;
    color: #FFFFFF;
    height: 100%;
    justify-content: center;
    width: 150px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    padding: 1px 15px;
}
button.accordion.active i.fa.fa-angle-up {
    transform: rotate(180deg);
    font-size: 20px;
}
.active, .accordion:hover {
  background-color: #fff;
}
button.accordion:hover {
  background-color: #fff;
}
.panel .table-striped tbody tr:nth-of-type(odd) {
    background-color: #fff !important;
}
.panel .table td, .panel .table th {
    border: 0px solid #dee2e6;
    border-radius: 6px;
}
.panel .table td:first-child {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    padding: 0.5rem;
}
h5.text-left.opp {
    margin-bottom: 20px;
}
span.indi {
    font: normal normal 600 18px/24px Montserrat;
    letter-spacing: 0.09px;
    text-transform: capitalize;
    margin-right: 20px;
}
span.indi img {
    position: relative;
    bottom: 2px;
    margin-right: 5px;
}
.panel .table td:nth-child(2), .panel .table th:nth-child(2){
    text-align: right;
    border-top-left-radius: 0px;
   border-bottom-left-radius: 0px;
}
.accordion.open + .panel {
    padding: 20px 40px;
    text-align: left;
    font: normal normal normal 17px/27px Montserrat;
    letter-spacing: 0.1px;
    color: #413F3F;
    transition: max-height 0.2s ease-out;
}
.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0,0,0,.05);
}
.panel {
    padding: 0px 40px;
    text-align: left;
    background-color: #f2f3f8;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    font: normal normal normal 17px/27px Montserrat;
}

/*.accordion:after {
  content: '\02EF';
  color: #777;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}

.active:after {
  content: "\02F0";
}*/


/** Responsive Css **/
@media (max-width: 1023px){
  .over-infosin {
      padding: 10px 10px;
  }
  .over-infosin.exp .over-infos.intab {
      padding: 10px;
  }
  .result {
      padding: 0px 10px;
  }
  .over-infos.grade {
      grid-template-columns: 1fr 1.5fr;
      padding: 10px 20px;
  }
  .btn-top {
      width: 90%;
  }

}


@media (max-width: 767px){
    button.tablinks {
      width: 50% !important;
  }
  .over-infosin {
      grid-template-columns: 1fr;
      padding: 0px;
  }
  .over-info p {
       height: auto; 
       padding: 15px 5px;
  }
    ul.result {
      flex-direction: column;
      padding: 10px 0px;
  }
  .result li {
      width: 100% !important;
  }
  .over-infos.grade {
      grid-template-columns: 1fr;
  }
  .over-infos.sev.img-op .over-infos.grade {
      padding: 30px 10px;
      grid-template-columns: 1fr;
  }
  .over-grade-img {
      margin-top: 40px;
  }
  .btn-top .btn-area-in {
      margin: 10px 0px;
      width: 100%;
  }
  .btn-top {
      flex-direction: column;
      width: 100%;
  }
  button.accordion h6 {
      font: normal normal bold 11px/21px Montserrat;
  }
  button.accordion h6 span {
      font: normal normal 600 14px/27px Montserrat;
      padding: 1px 1px;
      width: 45px;
  }
  button.accordion div:first-child {
      width: 70%;
      margin-right: 3px;
  }
  button.accordion div {
      display:flex;
  }
  span.indi {
      font: normal normal 600 14px/24px Montserrat;
      display: flex;
      align-items: center;
      margin-right: 10px;
  }
  span.indi img {
      width: 14px;
      height: 14px;
      margin-right: 5px;
  }
  button.accordion.active + .panel {
    padding: 20px 10px;
  }
    .over-infos.lab.opplab {
      padding: 10px 5px 20px;
  }
  p {
      font-size: 16px !important;
      text-align: justify;
  }
}

.over-infos.intab.active h5::before {
    background-color: #3BBE13;
}
.over-infos.intab.inactive h5::before {
    background-color: #F6491F;
}
.audited-url p{
    text-align: center;
    margin-bottom: 2rem;
}
.audited-url span{
    background: #F0F3F6 0% 0% no-repeat padding-box;
    padding: 0.75rem;
    border-radius: 1rem;
}


/* Tab custom design */
.react-tabs .react-tabs__tab--selected[aria-selected="true"]{ 
    background: #3F42EF 0% 0% no-repeat padding-box !important;
    width: 231px !important;
    color: #fff !important;
    text-align: center !important;
    font-weight: 600 !important;
    opacity: 1 !important;
    border-radius: 0px !important;
    padding: 15px 15px !important;
}

.react-tabs .react-tabs__tab[aria-selected="false"]{
    background: #F0F3F6 0% 0% no-repeat padding-box !important;
    font: normal normal normal 15px/22px Montserrat !important;
    letter-spacing: 0.1px !important;
    color: #525252 !important;
    width: 231px !important;
    text-align: center !important;
    font-weight: 600 !important;
    padding: 13px 15px !important;
    bottom: -1px !important;
}


#speed {
    border: 0px !important;
    display: flex !important;
    border-radius: 12px !important;
    gap: 20px !important;
    justify-content: center !important;
}

#speed li {
    border-radius: 12px !important;
    width: auto !important;
}

.react-tabs__tab[aria-selected="true"] svg{
    color: #fff !important;
    margin-right:5px !important;
}

.react-tabs__tab[aria-selected="false"] svg{
    color: #525252 !important;
    margin-right:5px !important;
}
@media (max-width:767px){
    .react-tabs .react-tabs__tab-list .react-tabs__tab {
        width: 30% !important;
        font-size: 14px !important;
    }
    .over-info p {
        display: inherit !important;
    }
    .accordion.open + .panel {
        padding: 20px 5px;
    }
    .audited-url span{
        padding: 0rem;
    }
}
/* Tab custom design */
span.crit-score{
    background-color: #f0190a;
    padding: 1px 10px !important;
}
span.moderate-score{
    background-color: #ff6600;
    padding: 1px 10px !important;
}
span.low-score{
    background-color: #f5ad11;
    padding: 1px 10px !important;
}
span.passed-score{
    background-color: #3BBE13;
    padding: 1px 10px !important;
}
.crit{
    color: #f0190a;
}
.moderate{
    color:#ff6600;
}
.low{
    color:#f5ad11 !important;
}
.passed{
    color:#3BBE13 !important;
}
.success-txt{
    color:#3BBE13 !important;
}
.warning-txt,.content .error-txt{
    color: #F6491F;
}

.show{
    display: block;
}
.hide{
    display: none;
}

.filter{
    float: right;
    margin-top: 15px;
}
.filter select{
    width: 8rem;
    text-align: center;
    float: right;
    margin: 0;
    height: 2rem;
}
.main-title{
    display: inline-block !important;
}
.filter span{
    position: absolute;
    margin: 5px;
}
tr.sub-item td:first-child a {
    padding-left: 30px;
    position:relative;
}
tr.sub-item td:first-child a:before,tr.sub-item td:first-child span:before {
    content:"\21B3";
    position:absolute;
    left:5px;
    color: #232121;
}
tr.sub-item td:first-child span:before{
    position: initial;
}
.__react_component_tooltip{
    font-weight: bolder;
}

.over-infosin.content-weight{
    grid-template-columns: 1fr 1fr;
}
.na-data{
    background-color: floralwhite;
    padding: 3rem;
}
.no-issues{
    text-align: center;
    padding: 2rem;
}
.no-issues span{
    background-color: white;
    padding: 1rem;
    border-radius: 0.25rem;
}
.no-issues span i{
    color: #3BBE13;
}

.audited-url {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 90%;
    margin: auto;
    align-items: center;
    justify-content: center;
}

.audited-url span {
    background-color: initial;
    display: grid;
    text-align: left;
    position: relative;
}

a.new-test {
    position: absolute;
    right: 20px;
    top: 3px;
}

.audited-url span a,.date {
    background-color: #F0F3F6;
    padding: 10px 15px;
    margin: 10px 0px;
    color: #000;
    border-radius: 3px;
    text-align: left;
    font-size: 20px;
}
.date{
    background-color: #F0F3F6 !important;
    padding: 10px 15px !important;
    border-radius: 3px !important;
    text-align: left !important;
}

.status-topin {
    padding: 20px 20px;
    border: 1px solid #C0C0C0;
    border-radius: 8px;
    flex: 1;
    display: flex;
    justify-content: center;
}

.status-top {
    gap: 20px;
    display: flex;
}

.status-topin:first-child {
    flex-direction: column;
    flex: 2;
    display: flex;
    border: 1px solid #F6491F;
    text-align: left;
}
.status-topin:first-child span{
    text-align: left;
    font: normal normal bold 30px/46px Montserrat;
    letter-spacing: 0.17px;
    color: #F6491F;
}

span.score {
    text-align: center;
    font: normal normal bold 44px/58px Montserrat;
    letter-spacing: 0.22px;
    color: #F6491F;
}

p.score-topin {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px;
}


p.score-topin.topin {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    text-align: left;
    font: normal normal bold 17px/30px Montserrat;
    letter-spacing: 0.11px;
    color: #413F3F;
}


p.score-topin.orange span.score {
    text-align: center;
    font: normal normal bold 44px/58px Montserrat;
    letter-spacing: 0.22px;
    color: #F6A717;
}

p.score-topin.purple span.score {
    text-align: center;
    font: normal normal bold 44px/58px Montserrat;
    letter-spacing: 0.22px;
    color: #C571E8;
}

span.score-desc {
    text-align: center;
    font: normal normal bold 16px/21px Montserrat;
    letter-spacing: 0.08px;
    color: #413F3F;
}


@media (max-width:640px){
    .status-top {
        flex-direction:column;
    }
    .audited-url{
        margin-top:40px;
        width:100%;
        grid-template-columns:1fr;
    }
    .over-infosin.content-weight {
        grid-template-columns: 1fr;
        overflow: scroll;
    }
    .seo-coming-soon{
        width: auto !important;
        margin-left: 0 !important;
    }
}
.footer {
    background: #fff 0% 0% no-repeat padding-box !important;
    padding: 1rem 0 0.25rem 0;
    width: 100vw;
    position: fixed;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    margin-top: 1rem;
    bottom: 0;
}
.footer p {
    text-align: center;
}
.react-tabs .react-tabs__tab--selected[aria-selected=true] path {
    color: #fff;
    margin-right: 5px;
}
.react-tabs .react-tabs__tab--selected[aria-selected=true] svg {
    margin-right: 5px;
}
.status-topin.success-txt{
    border:1px solid #3BBE13;
}

  .content p {
    text-align: center;
  }


  .audit-status {
    margin-left: 4px;
    background-color: #F6491F;
    border-radius: 10px;
    padding: 2px;
}

span.score.copy {
    color: #3F42EF !important;
}

.new-test {
    position: absolute;
    margin: 0 0 0 19% !important;
    padding: 0 !important;
    background: none !important;
    color: #3F42EF !important;
}

.score-topin.purple.copy{
    cursor: pointer;
}

.status-topin.copy {
    background-color: #D5E4FF;
    border: 1px solid #3F42EF;
}

.status-topin:last-child {
    margin: 1rem;
    padding: 1rem !important;
}

.seo-coming-soon{
    width: 50%;
    margin-left: 25%;
    text-align: center;
    margin-top: 4rem;
}
.seo-coming-soon h6{
    font-size: 34px;
    font-weight: bold;
    margin: 1rem;
}
.seo-coming-soon p{
    font-size: 23px;
}

.expert{
    margin-top: 2rem;
}
.expert-left{
    width: 76%;
    font-size: 17px;
    font-weight: bold;
    display: inline-block;
    margin-left: 3%;
}
.expert-right{
    display: inline-block;
    position: absolute;
}
.expert-button, .expert-button:hover {
    background-color: #F0503F;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer !important;
    border-radius: 1rem;
    font-weight: bold;
  }

  @media (max-width:767px){
    .expert {
        flex-direction: column;
        width: 100%;
        margin: 20px 0px;
        padding: 10px;
    }
    .expert-right {
        display: flex;
        justify-content: center;
        position: relative;
    }
    .expert-left {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .expert-left span {
        text-align: center  !important;
    }
    .loading ul {
        margin-left: 10% !important;
    }
    .loading > img {
        margin-left: 35% !important;
        height: 100px !important;
    }
  }

.similar-color tr td:first-child {
    text-align: right;
}
.similar-color tr td:nth-child(2) {
    text-align: left !important;
    padding: 8px;
}
.similar-color thead tr th:first-child {
    text-align: right;
}
.similar-color thead tr th:nth-child(2) {
    text-align: left !important;
}
span.tiny-text {
    font-size: 10px;
    margin-right: 10px;
}
.spinner-section, .spinner,.spinner-text {
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
.spinner-section{
    width: auto;
    height: 250px;
}
.spinner{
    width: 100px;
    height: 100px;
}
.spinner-text{
    width: auto;
    height: 100px;
    top:200px;
    text-align: center;
    font-size: 20px;
}

.Collapsible__contentInner {
    border: 1px solid transparent;
}

.score-crit{
    color: red !important;
}
.score-moderate{
    color: #ff6600 !important;
}
.score-passed{
    color: #3BBE13 !important;
}


/* seo audit */
.seo-scores .score{
    width: 12rem;
}
.score{
    display: inline-block;
}

/* audit-status */
.audit-details{
    position: fixed;
    bottom: 2rem;
    left: 2rem;
    z-index: 998;
}
.audit-details-completed{
    list-style-image: url('../../../../public/img/passed.png');
}
.audit-details-not-completed{
    list-style-image: url('../../../../public/img/failed.png');
}
/* audit-status */

/* Tab icon */
.main-tab-icon{
    margin-right: 10px;
}
.main-tab-loader-icon{
    width: 25px;
    margin-left: 5px;
}
@media (max-width:767px){
    .main-tab-title{
        display: none;
    }
}
/* Tab icon */

p.content-load{
    font-size: 20px;
}

.loader-txt p{
    margin-top: 100px;
    font-size: 20px;
    text-align: center;
}
.loader-txt img{
    margin: auto;
    display: block;
}
span.main-tab-title.disabled {
    color: lightgray;
}
.security-error{
    font-size: 20px;
    margin-top: 5rem;
}
.react-tabs {
    margin-bottom: 16rem;
}
.react-tabs__tab.react-tabs__tab--selected .main-tab-icon.black{
    display:none;
}
.react-tabs__tab.react-tabs__tab--selected .main-tab-icon.white{
    display:inline;
}
.react-tabs__tab .main-tab-icon.white{
    display:none;
}