p {
    margin: 0 0 10px;
}
@media only screen and (max-device-width: 480px){
p {
    font-size: 17px !important;
}}
a {
    text-decoration: none;
}
.row {
    margin-right: -15px;
    margin-left: -15px;
}
.aligncenter {
    margin: 0 auto;
    text-align: center;
    display: block;
}
.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
}


div {
    display: block;
}
.footer-sticky {
  position: sticky;
  bottom: 0px;
  background: #D0E5FF;
  border: 1px solid #1E395C;
  border-radius: 20px 20px 0px 0px;
  padding: 0px;
  max-width: 900px;
  margin: auto;
  z-index: 9;
}
#bottomsticky {
width: 100%;
height: auto;
}
@media (min-width: 576px){#bottomsticky { display: flex;}}
#bottomstickyfirst {
flex: 0 1 20%;
}
#bottomstickysecond {
flex: 0 1 50%;
}
@media (min-width: 576px){.footer-sticky-contact-button{margin-top: 7px;}}
@media (max-width: 991px){
.hidden-sm {
    display: none!important;
}}
.footer-sticky-contact-img{margin-top: -70px;}
.footer-sticky-title{font-size:18px;line-height:1.625;font-weight:600;margin-bottom: 2px;font-family:Roboto,sans-serif;}
.footer-sticky-description{font-size:14px;line-height:1.71429;font-weight:300;margin-bottom: 0px;}
.footer-sticky-contact-button .btn{letter-spacing:2px;padding:8px 20px 8px;
  background: transparent linear-gradient(180deg, #223B59 0%, #070C12 100%) 0% 0% no-repeat padding-box;
box-shadow: 0px 6px 10px #09152633;
font-size: 13px;
border-radius: 5px;border: none;color: #fff;}
.footer-sticky-contact-button .btn:hover{color:#fff;}

@media (min-width: 992px){.footer-sticky{margin-top: 10px;}}
@media (max-width: 991px){.footer-sticky{max-width: 100%;border-radius: 0;}}
@media (max-width: 767px){.footer-sticky{padding:15px 20px;max-width: 100%;border-radius: 0;}}
@media (max-width: 575px){
.footer-sticky-title{font-size:15px;line-height:1.625;font-weight:600;margin-bottom: 2px;}
.footer-sticky-description{font-size:15px;line-height:1.71429;font-weight:300;margin-bottom: 20px;}
.footer-sticky{text-align: center;}
.footer-sticky .col-xss-12{width: 100%;}
.footer-sticky .col-xs-height {display: block;}
}
@media (max-width: 767px){
    .hidden-xs {
        display: none!important;
    }
    #bottomstickysecond {
        flex: 0 1 60%;
      }
}
.footer-sticky-contact-button{margin-left: auto;margin-right: auto;}

@media (min-width: 576px)
{
    .footer-sticky-contact-button {margin-top: 10px;}
}