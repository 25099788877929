/* info */
.info{
    display:grid;
    grid-template-columns: 2fr 4fr;
    gap: 30px;
    margin-top:20px;
    font-size:14px;
}
.info-url{
    display:grid;
    grid-template-columns: 1fr 1fr;
    gap:5px;
}
.info-date,.info-copy{
    display:grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-gap: 5px;
    gap: 5px;
}

.info-url span:nth-child(3){
      grid-column-start: 1;
      grid-column-end: 3;
      text-overflow: ellipsis;
      overflow: hidden; 
      width: auto;
      white-space: nowrap;
}
.info-url span:nth-child(3) a{
    color: #413F3F;
    font: normal normal 600 14px/27px Montserrat;
}
.info-url span:nth-child(2){
    text-align:right;
    color: #3F42EF;
}
.info-url span:nth-child(2) a{
    color: #3F42EF;
    font-size:14px;
}
.info-date span:nth-child(2),.info-url span:nth-child(3),.info-date span:nth-child(3),.info-date span:nth-child(4){
      background-color: #F7F8F9;
      padding: 10px;
      border: 1px solid #C0C0C0;
      border-radius: 1rem;
      color: #413F3F;
      font-size: 14px;
      text-align: left;
}
.info-date span{
    font: normal normal 600 14px/22px Montserrat;
    margin-right: 10px;
}
.info-date span:nth-child(4){
    font: normal normal 600 13px/22px Montserrat;
}
.info-date span:nth-child(1)
{
    grid-column-start: 1;
    grid-column-end: 4;
}
.info-copy span:nth-child(2){
    text-align:center;
    border: 1px solid #3F42EF;
    background-color:#F2F7FF;
    color:#3F42EF;
    font-size:14px;
}

@media (max-width:640px){
    .info{
    grid-template-columns: 1fr;
    gap: 15px;
    }
}
/* info */

/* score & summary */
.seo{
    display: grid;
    grid-template-columns: 2fr 4fr;
    margin-top: 1rem;
    gap: 20px;
    justify-items: center;
}

.seo-summary{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    margin-top:20px;
    font-size:20px;
    justify-items: center;
    align-items: center;
    text-align:center;
}
.seo-summary div {
    padding: 30px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 17px;
    opacity: 1;
    width:100%;
}
.total-pages
{
    border: 1px solid #3173e5;
}
.seo-passed{
    border: 1px solid #3BBE13;
}
.seo-error{
    border: 1px solid #F6491F;
}
.seo-warning{
    border: 1px solid #F6A717;
}
.seo-notices{
    border: 1px solid #3173E5;
}
.seo-summary div span,.seo-analytics div span {
    display:block;
}
.seo-summary div span:nth-child(1){
    font: normal normal bold 30px/48px Montserrat;
    color:#484848;
}
.seo-summary div span:nth-child(2){
    font: normal normal 600 18px/24px Montserrat;
}
.seo-passed span:nth-child(2){
    color:#3BBE13;
}
.seo-error span:nth-child(2){
    color:#F6491F;
}
.seo-warning span:nth-child(2){
    color:#F6A717;
}
.seo-notices span:nth-child(2){
    color:#3173E5;
}

@media (max-width:640px){
    .health-score{
        width: 200px;
    }
    .seo{
        grid-template-columns: 1fr;
    }
    .seo-summary{
        display: block;       
    }
    .seo-summary div,.seo-analytics div{
        margin-bottom: 10px;
    }
}
.health-score {
    min-width: 200px;
    margin-top: 3rem;
}
/* score & summary */

/* Analytics */
.seo-analytics{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    margin-top:20px;
    font-size:20px;
    justify-items: center;
    align-items: center;
    text-align:center;
}
.seo-analytics div {
    padding: 30px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 17px;
    opacity: 1;
    width:100%;
}
.seo-analytics{
    grid-template-columns:1fr 1fr 1fr 1fr;
}


.seo-analytics div{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #3173E5;
    border-radius: 10px;
    width:100%;
    padding:25px;
}
.seo-analytics div:nth-child(5){
    grid-column-start:1;
    grid-column-end:3
}

.seo-analytics div span:nth-child(odd){
    text-align: center;
    font: normal normal bold 36px/48px Montserrat;
    letter-spacing: 0.18px;
    color: #484848;
}
.seo-analytics div span:nth-child(even){
    text-align: center;
    font: normal normal 600 18px/24px Montserrat;
    letter-spacing: 0.09px;
    color: #484848;
}
.analytics-title{
    font: normal normal 600 30px/40px Montserrat;
    letter-spacing: 0.15px;
    color: #484848;
    margin:50px 0 25px 0;
}

@media (max-width:640px){
    .seo-analytics{
        display:block;
    }
}
/* Analytics */

/* SEO Report */
.seo-report{
    margin-top:50px;
}
.seo-report .col-title h6{
    margin-left:20px;
}
.seo-report table tbody tr td:nth-child(2),.seo-report table tbody tr td:nth-child(3){
    text-align:center;
}
.seo-report .indi span {
    margin-right: 25px;
}
@media (max-width:640px){
    .seo-report .indi span {
        margin-right: 5px;
    }
}
/* SEO Report */

.seo-progressbar span{
    display: block;
    text-align: center;
}
.seo-progressbar span{
    font: normal normal 600 36px/36px Montserrat;
    color: #413F3F;
    opacity: 1;
    margin-top: 10px;
}
.seo-progressbar p{
    text-align: center;
}
