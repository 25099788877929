.App {
  display: flex;
  justify-content: center;
}
.App form input {
  display: block;
  width: 51rem;
  height: 3rem;
  padding: 0.5rem;
  font-size: 1.1em;
  font-weight: 500;
  margin-bottom: 2rem;
}
.start-audit {
  border: none;
  padding: 1rem;
  width: 21.2rem;
  font-size: 1.2em;
  border-radius: 0.2rem;
  cursor: pointer;
  background: #3F42EF 0% 0% no-repeat padding-box;
  color: white;
  font-size: 20px;
  font-family: fantasy;
  display: block;
}
.message {
  font-size: 1.2em;
  color: red;
}
select {
  display: block;
  width: 51.3rem;
  height: 3rem;
  margin: 1rem 0 1rem 0;
}
option{
  font-size: larger;
}
input[type="checkbox"]{
  display: inline;
  width: auto;
  height: auto;
  margin: 2rem 0 2rem 0;
}
.Hide{
  display: none;
}
.App-logo {
  width: 300px;
  height: 100px;
  display: inline-block;
}

.Header {
  display: block;
  width: 100%;
  text-align: center;
}